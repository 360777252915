import React from "react";
import { Link } from "react-router-dom";
import duimages from "../Images/du.png";
import denimages from "../Images/den.png";
import dipimages from "../Images/dip.png";
import logout from "../Images/logout.png";
import MoneyCounter from "./MoneyCounter";

const Header = () => {
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-3">
            <div className="logo">
              <Link to="/">DRC</Link>
            </div>
          </div>
          <div className="col-md-8 col-9 d-flex justify-content-end v">
            <nav>
              <ul>
                <li>
                  <Link to="/dashboard">
                    {" "}
                    <img src={duimages} alt="Du" />
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/density">
                    {" "}
                    <img src={denimages} alt="Den" />
                    Density
                  </Link>
                </li>
                <li>
                  <Link to="/dip">
                    {" "}
                    <img src={dipimages} alt="Dip" />
                    Dip
                  </Link>
                </li>
                <li>
                  <Link>
                    <MoneyCounter /> Calculator
                  </Link>
                </li>
                <li>
                  <Link to="/logout">
                    {" "}
                    <img src={logout} alt="Logout" /> Logout
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
