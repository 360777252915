import logo from './logo.svg';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
//import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Import Routes and other components
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import LoginForm from "./Component/LoginForm";
import Dashboard from "./Component/Dashboard";
import Density from "./Component/Density";
import Dip from "./Component/Dip";
import Logout from "./Component/Logout";



const App = () => (
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route
          path="/density"
          element={<ProtectedRoute element={<Density />} />}
        />
        <Route
          path="/dip"
          element={<ProtectedRoute element={<Dip />} />}
        />
        
        <Route
          path="/logout"
          element={<ProtectedRoute element={<Logout />} />}
        />
       
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  </AuthProvider>
);

export default App;
