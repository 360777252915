// AddProductForm.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

const AddProductForm = ({ fetchProducts, product, setProduct }) => {
  const navigate = useNavigate(); // Call useNavigate to get navigate function
  const { logout } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(
        "https://drc.freewebhostmost.com/admin/api/readingsave",
        product,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //console.log('Product added:', response.data);
      setSuccess("Item added successfully!");
      fetchProducts();

      // Clear form after successful submission
      setProduct({
        MS_opening: "",
        MS_closing: "",
        MS_testing: "",
        MS_subtotal: "",
        MS_price: "",
        MS_total: "",
        HSD_opening: "",
        HSD_closing: "",
        HSD_testing: "",
        HSD_subtotal: "",
        HSD_price: "",
        HSD_total: "",
        credit1: "",
        card: "",
        scan: "",
        cash: "",
        grandTotal: "",
      });
    } catch (error) {
      //setError('Failed to add product: ' + (error.response?.data?.message || error.message));
      //setLoading(false);
      logout();
      navigate("/"); // Use navigate to redirect
    }
  };

  // Calculation
  const calculateSubtotal = (opening, closing, testing) => {
    return closing - opening - testing;
  };

  const calculateTotal = (subtotal, price) => {
    return subtotal * price;
  };

  const calculateGrandTotal = (
    msTotal,
    hsdTotal,
    credit1,
    card,
    scan,
    cash
  ) => {
    return msTotal + hsdTotal - credit1 - card - scan - cash;
  };
  // Automatically update subtotal, total, and grand total when relevant values change
  useEffect(() => {
    const msSubtotal = calculateSubtotal(
      product.MS_opening,
      product.MS_closing,
      product.MS_testing
    );
    const hsdSubtotal = calculateSubtotal(
      product.HSD_opening,
      product.HSD_closing,
      product.HSD_testing
    );

    const msTotal = calculateTotal(msSubtotal, product.MS_price);
    const hsdTotal = calculateTotal(hsdSubtotal, product.HSD_price);

    const grandTotal = calculateGrandTotal(
      msTotal,
      hsdTotal,
      product.credit1,
      product.card,
      product.scan,
      product.cash
    );

    setProduct({
      ...product,
      MS_subtotal: msSubtotal,
      HSD_subtotal: hsdSubtotal,
      MS_total: msTotal,
      HSD_total: hsdTotal,
      grandTotal: grandTotal,
    });
  }, [
    product.MS_opening,
    product.MS_closing,
    product.MS_testing,
    product.MS_price,
    product.HSD_opening,
    product.HSD_closing,
    product.HSD_testing,
    product.HSD_price,
    product.credit1,
    product.card,
    product.scan,
    product.cash,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="product-form">
        <div className="row">
          <div className="col-md-1">
            <p>MS</p>
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="MS_opening"
              className="form-control"
              placeholder="Opening"
              value={product.MS_opening}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="MS_closing"
              className="form-control"
              placeholder="Closing"
              value={product.MS_closing}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-1 col-6">
            <input
              type="number"
              name="MS_testing"
              className="form-control"
              placeholder="Testing"
              value={product.MS_testing}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="MS_subtotal"
              className="form-control"
              placeholder="Subtotal"
              value={product.MS_subtotal}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="MS_price"
              className="form-control"
              placeholder="Price"
              value={product.MS_price}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="MS_total"
              className="form-control"
              placeholder="Total"
              value={product.MS_total}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="product-form">
        <div className="row">
          <div className="col-md-1">
            <p>HSD</p>
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="HSD_opening"
              className="form-control"
              placeholder="Opening"
              value={product.HSD_opening}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="HSD_closing"
              className="form-control"
              placeholder="Closing"
              value={product.HSD_closing}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-1 col-6">
            <input
              type="number"
              name="HSD_testing"
              className="form-control"
              placeholder="Testing"
              value={product.HSD_testing}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="HSD_subtotal"
              className="form-control"
              placeholder="Subtotal"
              value={product.HSD_subtotal}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="HSD_price"
              className="form-control"
              placeholder="Price"
              value={product.HSD_price}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 col-6">
            <input
              type="number"
              name="HSD_total"
              className="form-control"
              placeholder="Total"
              value={product.HSD_total}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="product-form">
        <div className="row">
          <div className="col-md-3 col-6">
            <input
              type="number"
              name="credit1"
              className="form-control"
              placeholder="Credit"
              value={product.credit1}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3 col-6">
            <input
              type="number"
              name="card"
              className="form-control"
              placeholder="Card"
              value={product.card}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3 col-6">
            <input
              type="number"
              name="scan"
              className="form-control"
              placeholder="Scan"
              value={product.scan}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3 col-6">
            <input
              type="number"
              name="cash"
              className="form-control"
              placeholder="Cash"
              value={product.cash}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-3 col-6">
            <input
              type="number"
              name="grandTotal"
              className="form-control"
              placeholder="Grand Total"
              value={product.grandTotal}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6 col-6">
            <button
              className="btn btn-primary bt-form"
              type="submit"
              disabled={loading}
            >
              {loading ? "Adding..." : "Add Product"}
            </button>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {success && <p style={{ color: "green" }}>{success}</p>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddProductForm;
